<template>
    <div>
        <van-popup v-model="ruleShow" :close-on-click-overlay="false">
            <p>活动规则</p>
            <div class="rule">
                <div class="rule-title">1. 参加方式</div>
                <div class="rule-content">
                    <span>-发起活动：仅限加拿大用户通过<a href="https://www.moobyyoho.com">MOOBYYOHO.COM</a>官方网站发起砍价。发起砍价后，发起者可将砍价商品海报图保存到本地，并分享到社交软件的加拿大地区好友和发起者社交圈，邀请好友帮助参与者获取砍价商品优惠金额。</span>
                    <div></div>
                    <span>-帮助好友获取砍价商品优惠：好友可通过参与者分享的海报的二维码或连接登录后进入活动页面，可帮助好友获取1次活动商品优惠。每位用户（同一个手机号码），针对同一好友发起的砍价活动，仅可帮助其获取1次随机金额优惠。</span>
                </div>
                <div class="rule-title">2. 如何购买砍价商品</div>
                <div class="rule-content">
                    MOOBY平台所有砍价商品，完成对应需砍刀数，即可视为获得该商品单一商品规格的砍价资格。砍价商品有数量限制，售完将被视为结束。
                </div>
                <div class="rule-title">3. 优惠金额</div>
                <div class="rule-content">
                    每位好友帮助获取的优惠金额随机，当砍价商品优惠金额达到当前最低优惠，该砍价商品不能继续获取优惠金额。
                </div>
                <div class="rule-title">4. 活动时间</div>
                <div class="rule-content">
                    每个砍价商品都有结束时间。请在砍价活动结束前完成砍价并购买，方可视为有效。活动时间结束后，未购买。平台将视为用户放弃已经获得的砍价特权。
                </div>
            </div>
            <div class="confirm" @click.stop="sure">确定</div>
        </van-popup>
    </div>
</template>
<script>
export default {
    props: ['ruleShow'],
    data() {
        return {
        }
    },
    created() {
        
    },
    methods: {
        sure() {
            this.$emit('rule')
        }
    }
};
</script>
<style scoped lang='less'>
    .van-popup {
        width: 291px;
        padding: 20px 20px 0;
        background: #fff;
        border-radius: 12px;
        font-size: 14px;
        box-sizing: border-box;
        p {
            text-align: center;
            margin-bottom: 12px;   
            font-size: 16px;       
        }
        .rule {
            overflow-x: hidden;
            overflow-y: auto;
            height: 377px;
            .rule-title {
                margin-bottom: 12px;
            }
            .rule-content {
                margin-bottom: 20px;
                a {
                    color: #48A2FF;
                }
            }
        }
        .confirm {
            margin-top: 5px;
            box-shadow: inset 0px 1px 0px rgba(153, 153, 153, 0.25);
            text-align: center;
            padding: 13px 0;
            color: #FF5049;
        }
        
    }
</style>