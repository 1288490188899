<template>
    <section class="m-haggle m-scroll" ref="scroll"  @scroll="handleScroll($event)">
        <div class="header" :style="`background:rgba(255,255,255,${(this.offsetTop-100) / 100});`">
            <img @click="$router.go(-1)" src="@/assets/goods/icon_back_2@2x.png" alt="back">
            <span :style="`opacity:${(this.offsetTop -60)  / 60};`">砍价专区</span>
            <router-link to="/zh/cart" >
                <img src="@/assets/goods/cart.png" alt="img">
                <m-dot :animate="dotAnimate" ref="dot"></m-dot>
            </router-link>
        </div>
        <div class="header-bg" :style="`opacity:${(200-this.offsetTop) / 200};`">
            <div class="rule" @click="rule" :style="`opacity:${(200-this.offsetTop) / 200};`">活动规则</div>
        </div>
        <div class="haggle-goods">
            <div class="haggle-title">
                <img src="@/assets/haggle/Group 3086.png" alt="">
                <span>砍价专区</span>
                <img src="@/assets/haggle/Group 3086.png" alt="">
            </div>
            <van-list 
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad" >
                <ul class="m-goods-columns m-pad">
                    <li class="goods-columns-1" v-for="(item, index) in goodsList" :key="index">
                        <div class="m-goods">
                            <div class="haggling" v-if="item.show">砍价中</div>
                            <img class="goods-img" v-if="item.goods.image" :src="item.goods.image.remote_url" alt="goods">
                            <div class="goods-info">
                                <p class="van-multi-ellipsis--l2">{{item.goods_info.name}}</p>
                                <div class="count">
                                    <span>距离结束：</span>
                                    <van-count-down :time="item.remaining_time*1000" format="DD 天 HH:mm:ss" />
                                </div>
                                <div class="goods-price">
                                    <div>
                                        <div class="down">最低价</div>
                                        <p>
                                            <span>${{item.bargain_goods_sku[0].sales_price}}</span>
                                            <span>${{item.bargain_goods_sku[0].goods_sku.price}}</span>
                                        </p>
                                    </div>
                                    <div class="goods-btn">
                                        <router-link v-if="!item.show" :to="{path:`/zh/haggle/${item.goods_id}`,query:{id:item.id}}" >去砍价</router-link>
                                        <router-link v-else :to="{path:`/zh/haggleDetail/${item.goods_id}`,query:{haggle_id:item.bargain_user_goods_sku.id}}" >继续砍</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </van-list> 
            
        </div>
        <!-- 活动规则 -->
        <m-rule @rule="rule" :ruleShow="ruleShow"></m-rule>
        <div class="my-haggle">
            <div class="btn" @click="$router.push('/zh/myHaggle')">我的砍价</div>
        </div>
    </section>
</template>
<script>
import MDot from '@/components/zh/m-dot.vue'
import MRule from './rule.vue'

import { haggle_list } from '@/api/zh/haggle.js'
export default {
    components:{ MDot, MRule },
    data() {
        return {
            offsetTop : 0,
            dotAnimate: false,
            ruleShow: false,
            loading: false,
            finished: false,
            page: 0,
            goodsList: [],
        }
    },
    created() {
        let that = this
        let isMiniProgram = that.getQueryVariable('isminiprogram')
        if(isMiniProgram && this.isWxMp()) { 
            this.$notify({
                type: 'warning',
                message: '小程序用户无法参与',
                onClose: () => {
                    that.$router.push('/zh')
                }
            })
        }
    },
    mounted() {
        sessionStorage.removeItem('haggle_id')
        sessionStorage.removeItem('haggle_flag')
        // this.getData()
    },
    methods: {
        handleScroll(e) {
            this.offsetTop = e.target.scrollTop
        },
        getQueryVariable(variable) {
            let query = window.location.search.substring(1);
            let vars = query.split("&");
            for (let i=0;i<vars.length;i++) {
                    let pair = vars[i].split("=");
                    if(pair[0] == variable){return pair[1];}
            }
            return(false);
        },
        isWxMp() {
            var ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == 'micromessenger') { //判断是否是微信环境
                //微信环境
                if (ua.match(/miniProgram/i) == 'miniprogram') {
                // 小程序环境下逻辑 
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        rule() {
            this.ruleShow = !this.ruleShow
        },
        onLoad() {
            this.page ++
            this.getData()
        },
        getData() {
            haggle_list({page: this.page}).then(res => {
                if(res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                    if(this.goodsList.length >= res.data.total) {
                        this.finished = true
                    }
                    this.goodsList.forEach(item => {
                        if(item.bargain_user_goods_sku) {
                            item.show = true
                        }
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },
    }
};
</script>
<style scoped lang="less">
    .m-haggle {
        width: 100%;
        height: 100%;
        padding-top: 44px;
        background-color: #F25149;
        overflow-x: hidden;
        overflow-y: auto;
        .header {
            width: 100%;
            height: 44px;
            padding: 0  16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #79A8F0;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 13;
            &>img {
                width: 30px;
                height: 30px;
            }
            &>span {
                font-size: 4.8vw;
                font-weight: 400;
                color: #333333;
                letter-spacing: 1px;
            }
            .search {
                width: 255px;
                height: 32px;
                background-color: #fff;
                position: relative;
                border-radius: 30px;
                &>img {
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 6px;
                    left: 12px;
                }
            }
            &>a {
                position: relative;
                display: flex;
                align-items: center;
                &>img {
                    width: 30px;
                    height: 30px;
                }
            }
            
        }
        .header-bg {
            width: 100%;
            height: 260px;
            background-size: cover;
            position: fixed;
            top: 0;
            left: 0;
            z-index:8;
            transition: all 0.3s;
            background: url('../../../assets/haggle/header-bg.png') no-repeat;
            background-size: 100%;
            .rule {
                position: absolute;
                right: 0;
                top: 118px;
                padding: 6px 16px;
                border-radius: 15px 0 0 15px;
                font-size: 12px;
                border: 1px solid #fff;
                border-right: none;
                background: linear-gradient(90deg, #6B84F4 0%, #29FFCC 100%);;
                color: #fff;
            }
        }
        .haggle-goods {
            margin: 155px auto 90px;
            padding: 51px 0 12px;
            border: 2px solid #fff;
            border-radius: 12px;
            width: 359px;
            position: relative;
            z-index: 10;
            background: #FEEACC;
            .haggle-title {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                color: #fff;
                font-size: 18px;
                font-weight: bold;
                width: 180px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: url('../../../assets/haggle/title.png') no-repeat;
                background-size: 100% 100%;
                img {
                    width: 16px;
                    height: 10px;
                    &:nth-of-type(2) {
                        transform: rotate(180deg);
                    }
                }
                span {
                    margin: 0 8px;
                }
            }
            .m-pad {
                padding: 0 8px;
            }
            .goods-columns-1 {
                background: #fff;
                width: 100%;
                border-radius:12px;
                background: #fff;
                margin-bottom: 12px;
                box-shadow: 0px 2px 16px rgba(204, 204, 204, 0.2);
                position: relative;
                overflow: hidden;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 81px;
                    height: 143px;
                    position: absolute;
                    top: 8px;
                    right: 0;
                    background: url('../../../assets/haggle/Vector\ 20.png') no-repeat;
                    background-size: 100% 100%;
                    z-index: 1;
                }
                .m-goods {
                    display: flex;
                    position: relative;
                    padding: 8px;
                    z-index: 2;
                    .haggling {
                        padding: 3px 8px;
                        position: absolute;
                        top: 8px;
                        left: 8px;
                        font-size: 12px;
                        color: #fff;
                        background: url('../../../assets/haggle/Rectangle 102.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    &>.goods-img {
                        width: 110px;
                        height: 110px;
                        border-radius: 5px;
                        margin-right:8px;
                    }
                    .goods-info {
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                        flex: 1;
                        &>p {
                            font-size: 14px;
                            // letter-spacing: 0.31px;
                            font-weight: 400;
                            line-height: 18px;
                            color: #333;
                        }
                        .count {
                            display: flex;
                            font-size: 12px;
                            color: #EF4422;
                            border: 1px solid #EF4D2D;
                            width: 170px;
                            height: 16px;
                            align-items: center;
                            border-radius: 8px;
                            margin-top: 4px;
                            margin-bottom: 14px;
                            padding-left: 8px;
                            box-sizing: border-box;
                            span {
                                display: inline-block;
                                line-height: 14px;
                            }
                            .van-count-down {
                                height: 14px;
                                line-height: 14px;
                                color: #EF4422;
                                font-size: 12px;
                            }
                        }
                        .goods-price {
                            display: flex;
                            align-items:flex-end;
                            justify-content: space-between;
                            font-size: 12px;
                            &>div:nth-child(1) {
                                .down {
                                    width: 42px;
                                    text-align: center;
                                    color: #fff;
                                    font-size: 10px;
                                    background: linear-gradient(315deg, #F03C18 0.05%, #F06448 100%);
                                    border-radius: 2px;
                                }
                                p:nth-of-type(1){
                                    color: #ED2A24;
                                    margin-top: 4px;
                                    &>span:nth-child(1){
                                        font-size: 18px;
                                    }
                                    &>span:nth-child(2){
                                        margin-top: 2px;
                                        margin-left: 5px;
                                        font-size: 10px;
                                        color: #999999;
                                        text-decoration:line-through;
                                    }
                                }
                            }
                            .goods-btn {
                                a{
                                    display: inline-block;
                                    padding: 6px 10px;
                                    line-height: 1;
                                    background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
                                    border-radius: 22px;
                                    font-size: 14px;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
        .my-haggle {
            width: 100%;
            position: fixed;
            bottom: 0;
            height: 60px;
            background: #FAFAFA;
            display: flex;
            justify-content: center;
            z-index: 11;
            border-radius: 24px 24px 0 0;
            .btn {
                margin-top: 6px;
                width: 301px;
                height: 40px;
                color: #fff;
                background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
                border-radius: 22px;
                text-align: center;
                line-height: 40px;
            }
        }
    }
</style>